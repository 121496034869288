
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UploadedFile, { Row } from "@/entities/UploadedFile";
import GoogleMapLogic from "./Logic/GoogleMapLogic";
import {
  mdiHandBackRightOutline,
  mdiCircleOutline,
  mdiVectorPolygon,
  mdiSquareOutline,
  mdiDelete,
} from "@mdi/js";

/**
 * Display the rows that have been uploaded.
 */
@Component({
  name: "TableAndMapGoogleMap",
  components: {},
})
export default class GoogleMap extends Vue {
  /**
   * The uploaded file to display on the map
   */
  @Prop()
  public uploadedFile!: UploadedFile;
  /**
   * All of the polygons being displayed on the map
   */
  @Prop({ default: Array() })
  public overlayEvents!: google.maps.drawing.OverlayCompleteEvent[];
  /**
   * Function that creates a google maps info window, passed in to keep class generic
   */
  @Prop({ default: () => null })
  public createInfoWindow!: (
    marker: google.maps.Marker,
    row: Row
  ) => google.maps.InfoWindow;
  /**
   * A Set of indices of objects to hide
   */
  @Prop({ default: new Set() })
  public hiddenMarkerIndices!: Set<number>;
  /**
   * Whether or not to display a heatmap
   */
  @Prop({ type: Boolean, default: false })
  public displayHeatmap!: boolean;
  /**
   * Whether or not to display the markers
   */
  @Prop({ type: Boolean, default: false })
  public displayMarkers!: boolean;
  /**
   * Whether or not to display markers using clusters. Nice when there are a lot of markers.
   */
  @Prop({ type: Boolean, default: false })
  public displayClusters!: boolean;
  /**
   * The row that has been clicked if there is one
   */
  @Prop({ default: null })
  public clickedMarker!: Row | null;
  /**
   * Categorical column to use to determine the marker color and pie chart when clusters are activated.
   */
  @Prop({ default: null })
  private groupByKey!: number | null;
  /**
   * Used to adjust the opacity of the markers that are usually hidden
   */
  @Prop({ default: 0 })
  private unselectedMarkerOpacity!: number;
  /**
   * All of the uploaded geojson and shapefile layers
   */
  @Prop({ default: Array() })
  private layers!: { id: string, fileName: string, data: object | null }[];

  private mapLogic!: GoogleMapLogic;

  private drawingOptions = [
    { title: "Stop drawing", icon: mdiHandBackRightOutline },
    { title: "Draw a circle", icon: mdiCircleOutline },
    { title: "Draw a shape", icon: mdiVectorPolygon },
    { title: "Draw a rectangle", icon: mdiSquareOutline },
  ];
  private mdiDelete = mdiDelete;

  private get isEmbed() {
    return this.$route && this.$route.name === "Embed";
  }

  private get rootUrl() {
    return process.env.VUE_APP_BASE_URL;
  }

  @Watch("uploadedFile")
  private updateUploadedFile(): void {
    this.mapLogic.initMarkers();
  }

  @Watch("overlayEvents", { deep: true })
  private updateOverlayEvents(): void {
    this.mapLogic.initOverlayEvents();
  }

  @Watch("hiddenMarkerIndices")
  private updateHiddenMarkerIndices(
    newVals: Set<number>,
    oldVals: Set<number>
  ) {
    this.mapLogic.hiddenMarkerIndicesUpdated(newVals, oldVals);
  }

  @Watch("displayHeatmap")
  private updateDisplayHeatmap() {
    this.mapLogic.displayHeatmapChanged();
  }

  @Watch("displayMarkers")
  private updateDisplayMarkers() {
    this.mapLogic.displayMarkersChanged();
  }

  @Watch("displayClusters")
  private updateDisplayClusters() {
    this.mapLogic.displayClustersChanged();
  }

  @Watch("clickedMarker")
  private updateClickedMarker() {
    this.mapLogic.clickedMarkerChanged();
  }

  @Watch("groupByKey")
  private updateGroupByKey() {
    this.mapLogic.updateMarkerImages();
  }

  @Watch('unselectedMarkerOpacity')
  private updateUpdateunselectedMarkerOpacity() {
    this.mapLogic.updateUnselectedMarkerOpacity();
  }

  @Watch("layers")
  private updateLayers() {
    this.mapLogic.updateLayers();
  }

  private created(): void {
    this.mapLogic = new GoogleMapLogic(this);
    this.mapLogic.createMap();
  }

  private deleteSelectedOverlay() {
    this.mapLogic.deleteSelectedOverlay();
  }

  private deleteAllOverlays() {
    this.mapLogic.deleteAllOverlays();
  }

  private beforeDestroy(): void {
    this.mapLogic.beforeDestroy();
  }
}
